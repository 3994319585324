html {
  --primary-color: #364b7d;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.Error {
  color: red;
  font-size: large;
}

a{
  color: #337ab7;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
