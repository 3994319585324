body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body, html{
  height: 100%;
  width: 100%;
}

#root, #main, .App, .AuthenticationWrapper{
  display: block;
  width: 100%;
  height: 100vh;
}

.datepicker * {
  color: #fff !important;
}

.datepicker hr {
  visibility: hidden;
}